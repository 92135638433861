import $ from "jquery";

export default function modalNum() {
  function init() {
    $(".box-numeri .box-numeri__btn").on("click", function () {
      let btnId = $(this).attr('id');

      $("body").addClass("block-site");

      $(".modal-number")
        .removeAttr("aria-hidden")
        .attr("aria-modal", true)
        .attr("role", "dialog")
        .addClass("modal-number--open");

      $('.modal-number__content .modal-number__box[data-content="'+btnId+'"]').removeClass('d-none');
    });

    function closeModal() {
      $(".modal-number").removeClass("modal-number--open");
      $("body").removeClass("block-site");
      $(".modal-number")
        .removeAttr("aria-modal")
        .removeAttr("role")
        .attr("aria-hidden", true)
        .removeClass("active");

      $('.modal-number__content .modal-number__box').addClass('d-none');
    }
    
    $(".modal-number").on("click", ".modal-number__close", function () {
      closeModal();
    });

    $(document).on("keydown", function (e) {
      if (e.key === "Escape") {
        closeModal();
      }
    });
  }

  $(document).ready(function () {
    if ($(".box-numeri").length > 0) {
      init();
    }
  });
}
