import $ from "jquery";

export default function utils() {
	$("html").removeClass("no-js");

	$("a[href^=http], a.external, a:not([href*=sellainsights])").on(
		"click",
		function () {
			const url = this.href;
			const friendlyURL = url.split("/")[2];

			if (friendlyURL.includes("sellainsights")) {
				window.open(this.href, "_self");
			} else {
				window.open(this.href, "_blank");
			}
			return false;
		}
	);
}
