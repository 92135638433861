import $ from "jquery";

export default function progress() {
  function init() {
    let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let scrolled = (winScroll / height) * 100;
    document.getElementById("sella-progress-bar").style.width = scrolled + "%";
  }

  $(document).ready(function () {
    if ($("#sella-progress-bar").length > 0) {
      window.onscroll = function () {
        init();
      };
    }
  });
}
