import $ from "jquery";

export default function btnShort() {
  function init() {
    $(".btn-short").on("click", function () {
      $("body").addClass("block-site");
      $('.modal-short').addClass('modal-short--open');
    });

    $(".modal-short__close, .modal-short__back").on("click", function () {
      $("body").removeClass("block-site");
      $('.modal-short').removeClass('modal-short--open');
    });
  }

  $(document).ready(function () {
    if ($(".btn-short").length > 0) {
      init();
    }
  });
}
