import $ from "jquery";

export default function hamburger() {
  function init() {
    $(".hamburger").on("click", function () {
      if (!$(this).hasClass("open")) {
        $("body").addClass("block-site");
        $(this).addClass("open");
        $(".box-search").removeClass("box-search--open");
        $(".header__nav-wrap").addClass("header__nav-wrap--open");
      } else {
        $("body").removeClass("block-site");
        $(this).removeClass("open");
        $(".header__nav-wrap").removeClass("header__nav-wrap--open");
      }
    });
  }

  $(document).ready(function () {
    if ($(".hamburger").length > 0) {
      init();
    }
  });
}
