import $ from "jquery";

export default function header() {
  function init() {
    let lastScrollTop = 0;
    $(window).on("scroll", function () {
      let st = $(this).scrollTop();
      if (st === 0) {
        $('.header').removeClass('scroll-up');
        $('.header').removeClass('scroll-down');
      } else {
        if (st > lastScrollTop){
          $('.header').removeClass('scroll-up').addClass('scroll-down');
        } else {
          $('.header').removeClass('scroll-down').addClass('scroll-up');
        }
        lastScrollTop = st;
      }
    });
  }

  $(document).ready(function () {
    if ($(".header").length > 0) {
      init();
    }
  });
}
