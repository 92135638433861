import $ from 'jquery';
import 'slick-carousel';

export default function slider() {

  function sliderInsights() {
    $('.slider-insights').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

  function sliderArchivio() {
    $('.slider-archivio').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

  function sliderRelated() {
    $('.slider-related').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 4,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

  function sliderNewsDetail() {
    $('.slider-photogallery-detail').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: false,
      adaptiveHeight: false,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '20%'
    });
  }

  function sliderStartList() {
    var settingsStartList = {
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      slidesToShow: 1,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 9999,
          settings: "unslick"
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '10%',
            infinite: true
          }
        }
      ]
    };
    
    var slickSliderStartList =  $('.slider-start-list').slick(settingsStartList);

    $('.slider-start-list').slick(settingsStartList);

    $(window).on('resize', function() {
      if( $(window).width() < 1200 && !slickSliderStartList.hasClass('slick-initialized')) {
        $('.slider-start-list').slick(settingsStartList);
      }
    });
  }

  $( document ).ready(function() {

    if($('.slider-insights').length > 0) {
      sliderInsights();
    }

    if($('.slider-archivio').length > 0) {
      sliderArchivio();
    }

    if($('.slider-related').length > 0) {
      sliderRelated();
    }

    if($('.slider-photogallery-detail').length > 0) {
      sliderNewsDetail();
    }

    if($('.slider-start-list').length > 0) {
      sliderStartList();
    }

  });
}