import $ from "jquery";

export default function search() {
  function init() {
    //open search
    $(".box-search .box-search__btn").on("click", function () {
      if (!$(this).parent('.box-search').hasClass("open")) {
        $('.header__nav-wrap').removeClass("header__nav-wrap--open");
        $('.hamburger').removeClass("open");
        $(this).parent('.box-search').addClass("box-search--open");
      } else {
        $(this).parent('.box-search').removeClass("box-search--open");
      }
    });

    //close search
    $(".box-search .box-search__close").on("click", function () {
      $('.box-search').removeClass("box-search--open");
    });
  }

  $(document).ready(function () {
    if ($(".box-search").length > 0) {
      init();
    }
  });
}
