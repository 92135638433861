import './polyfill/currentScript'
import 'core-js/stable' 
import 'regenerator-runtime/runtime'

import utils from './utils'
import header from './header'
import hamburger from './hamburger'
import search from './box-search'
import slider from './slider'
import prototipo from './prototipo'
import liferay from './liferay'
import progress from './progress'
import btnShort from './btn-short'
import modalNum from './box-numeri'

const bundle_url = new URL(document.currentScript.src);
__webpack_public_path__ = bundle_url.pathname.substring(0, bundle_url.pathname.lastIndexOf("/")+1);

utils();
header();
hamburger();
search();
slider();
progress();
btnShort();
modalNum();

if (!process.env.LIFERAY_ENV) {
  prototipo();
}

if (process.env.LIFERAY_ENV) {
  liferay();
}