import $ from 'jquery';

export default function liferay() {

  function viewAll() {
    $('.js-viewall').on('click', function (e){
	  e.preventDefault();
      $(this).addClass('d-none').next('.js-viewless').removeClass('d-none').closest(".js-view-container").find("li").removeClass("d-none");
    });
    $('.js-viewless').on('click', function (e){
	  e.preventDefault();
      var maxElem = $(this).closest(".js-view-container").data("maxelem") || 5;
      $(this).addClass('d-none').prev('.js-viewall').removeClass('d-none').closest(".js-view-container").find(maxElem >= 0 ? "li:gt("+maxElem+")" : "li").addClass("d-none");
    });
  }

  $( document ).ready(function(){ 
    if ($('.js-viewall').length > 0) {
      viewAll();
    }
  });
}